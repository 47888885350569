<template>
  <div class="flex md6 xs12 xl4">
    <vac-card
      :title="$t('Retailers Coverage')"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="RETAILERS COVERAGE"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />

      <Actions
        show-percentage-switcher
        :active-switcher-button="activeSwitcherButton"
        :export-to-excel="exportToExcel"
        is-preview
        @togglePreview="togglePreview"
        @toggle-percentage="onToggle($event)"
      />

      <!--      <va-chart :data="data"-->
      <!--                :options="xOptions"-->
      <!--                type="horizontal-bar"/>-->
      <Bar
        v-if="!loading"
        :chart-id="'bar-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :css-classes="'bar export'"
        :chart-options="chartOptions"
      />

    </vac-card>
  </div>
</template>

<script>
import VaChart from "../statistics-template/charts/va-charts/VaChart";
import HelperExcel from "../../services/Helpers/HelperExcel";
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import {Bar} from 'vue-chartjs/legacy'

import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: "RetailersCoverage",
  components: {
    Actions,
    // VaChart,
    ExcelPreviewModal,
    Bar
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    // chartData() {
    //   if (this.activeSwitcherButton === 1) {
    //     return this.data
    //   } else {
    //     let chartData = this.data
    //     this.data.datasets.forEach((cd, index) => {
    //       chartData.datasets[index] = cd.data.map(cdd => {
    //         return (cdd * 100).toFixed(2)
    //       })
    //     })
    //     return chartData
    //   }
    // },
    chartOptions() {
      return {
        scaleStartValue: 0,
        scales: {
          xAxes: {
            display: true,
            ticks: {
              callback: (value) => {
                if (this.activeSwitcherButton === 1) {
                  return value
                } else {
                  return (value * 100) + "%"
                }
              }
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {

          tooltip: {
            mode: 'index',
            callbacks: {
              label: (ttItem) => {
                if (this.activeSwitcherButton === 1) {
                  return `${ttItem.dataset.label}: ${ttItem.parsed.x}`;
                } else {
                  return `${ttItem.dataset.label}: ${(ttItem.parsed.x * 100).toFixed(2)}%`;
                }
              }
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        }
      };
    },
    preparedData() {
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        // let sum = 0
        // datasets[0].data.forEach(d => {
        //   sum += d
        // })
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100).toFixed(2)}%`
        })
      }

      const returnObj = {}
      returnObj.labels = [...labels]
      returnObj.labels.unshift("Brands")
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = datasets.map(data => {
        returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
        for (let key = 0; key < data.data.length; key++) {
          if (typeof data.data[key] === 'number') {
            data.data[key] = data.data[key].toFixed(3)
          }
          returnObj.maxLength[key + 1] = Math.max(returnObj.maxLength[key + 1], String(data.data[key]).length)
        }

        return {
          data: [data.label, ...data.data]
        }
      })
      return returnObj;
    }
  },
  data() {
    return {
      xOptions: {
        scaleStartValue: 0,
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 1,
            },
          }],
        },
      },
      isPreview: false,
      activeSwitcherButton: 1,
      loading: false
    };
  },
  methods: {
    exportToExcel() {
      const customData = [];
      const customLabels = ["Retailer", "Coverage"];
      const {labels, datasets} = JSON.parse(JSON.stringify(this.data));
      if (this.activeSwitcherButton === 2) {
        datasets[0].data = datasets[0].data.map(d => {
          return `${(d * 100).toFixed(2)}%`
        })
      }

      datasets.forEach(data => {
        data.data.forEach((info, index) => {
          const obj = {
            "Retailer": labels[index],
            "Coverage": info,
          };
          customData.push(obj);
        });
      });

      HelperExcel.exportToExcel({}, "Retailers Coverage", customLabels, customData);
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    onToggle(val) {
      this.loading = true
      this.activeSwitcherButton = val
      setTimeout(() => {
        this.$nextTick(() => {
          this.loading = false
        })
      }, 100)
    }
  },
};
</script>

<style scoped>
.bar {
  height: 471px;
}
</style>
